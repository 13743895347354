<template>
  <b-select :placeholder="$t('en.select')" :name="name" :value="value" ref="select">
    <option
      v-for="option in list"
      :value="option.id"
      :key="option.id">
      {{ option.name }}
    </option>
  </b-select>
</template>
<script>
  import api from '@/api.js'
  export default {
    name: 'loadOption',
    created(){
      api.get(this.url, {
      }).then( function(response){
        this.list = response.data
      }.bind(this))
    },
    methods: {
      checkHtml5Validity: function() {
        return this.$refs.select.checkHtml5Validity()
      }
    },
    data(){
      return {
        list: {},
        key: 0
      }
    },
    props: ['url', 'name', 'value']
  }
</script>
