<template>
  <div>
    <b-input :name="name" v-model="fieldValue" style="display:none" ref="input" :required="required"></b-input>
    <v-select :options="list" label="name" @input="selected" ref="searchSelect" v-model="displayValue"></v-select>
  </div>
</template>
<script>
  import api from '@/api.js'
  import vSelect from 'vue-select'
  import 'vue-select/dist/vue-select.css'
  export default {
    name: 'dodoOption',
    components: {
      vSelect
    },
    created(){
      api.get(this.url, {
      }).then( function(response){
        this.list = response.data
        // this.fieldValue = this.value
        const criteria = { id: this.value }
        const returnValue = this.list.find(function(elem){
          return elem.id === this.id
        }, criteria)
        if ( returnValue !== undefined ) {
          this.displayValue = returnValue.name
          this.fieldValue = this.value
        } else {
          this.fieldValue = ''
          this.displayValue = ''
        }
      }.bind(this))
    },
    methods: {
      checkHtml5Validity: function() {
        return this.$refs.input.checkHtml5Validity()
      },
      selected: function(val){
        if (val != null) {
          this.fieldValue = val.id
        } else {
          this.fieldValue = null
        }
      }
    },
    watch: {
      value (){
        const criteria = { id: this.value }
        const returnValue = this.list.find(function(elem){
          return elem.id === this.id
        }, criteria)
        if ( returnValue !== undefined ) {
          this.displayValue = returnValue.name
          this.fieldValue = this.value
        } else {
          this.fieldValue = ''
          this.displayValue = ''
        }
      }
    },
    data(){
      return {
        list: [],
        key: 0,
        fieldValue: '',
        displayValue: '',
      }
    },
    props: ['url', 'name', 'value', 'required']
  }
</script>
