<template>
  <card-component
    :title="$t('en.detail')"
    icon="ballot"
    :bordered="true"
  >
    <b-table
      checkable
      :data="detailGridlData"  :bordered="true" striped >
    <b-table-column
      v-for="item in detailColumns" :key="item.id"  :label="item.label" :field="item.field" v-slot="props"  :header-class="item.type==null ? 'customer-table-header' : 'checkbox-cell'">
      <a v-if="item.linkid!=null && item.field!='contract_no'" @click="rowClick(props.row[item.linkid])">
        {{ props.row[item.field]}}
      </a>
      <a v-else-if="item.field==='contract_no'" @click="contractClick(props.row[item.linkid])">
        {{ props.row[item.field]}}
      </a>
      <span v-else-if="item.type=='delete'">
           <b-button type="is-danger"
                     icon-right="delete" @click="deleteClick(item.field, item.url, props.row)"/>
        </span>
      <span v-else>
          {{ props.row[item.field]}}
        </span>
    </b-table-column>
    </b-table>
  </card-component>
</template>

<script>
  import CardComponent from '@/components/CardComponent.vue'
  import api from '@/api.js'
  import router from '@/router'
  export default {
    name: 'detailResult',
    props: ['detailColumns', 'detailGridlData', 'tabChange', 'currentRow'],
    components: {
      CardComponent,
    },
    methods: {
      rowClick(row){
        this.$emit('addNewClick', 'detailUpdate', this.currentRow, row)
      },
      contractClick(row){
        router.push({ path: '/contract?id=' + row, replace: true, })
      },
      deleteClick: function(idName, url, row){
        const idValue = row[idName]
        this.$buefy.dialog.confirm({
          title: 'Deleting',
          message: '<span style>確認刪除?</span>',
          confirmText: 'Delete',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => this.confirmDelete(idName, url, idValue)
        })
      },
      async confirmDelete(idName, url, idValue){
        const resultData = await api.get(url + '?' + idName + '=' + idValue)
        const isPass = resultData.data.result
        if (isPass) {
          this.$buefy.snackbar.open({
            message: 'Done',
            queue: false
          })
        } else {
          this.$buefy.snackbar.open({
            message: '刪除失敗, 字數據未有清空',
            queue: false
          })
        }
        this.$emit('tabChange', this.currentRow)
        this.$emit('submitForm', '')
      },
    }
  }
</script>
<style scoped>
  a:hover{
    text-decoration: underline;
  }
</style>
