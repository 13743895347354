<template>

    <b-table
      :loading="isLoading"
      :data="data"
      :bordered="true"
      :paginated="isPaginated"
      :per-page="perPage"
      :current-page.sync="currentPage"
      :pagination-simple="isPaginationSimple"
      :pagination-position="paginationPosition"
      :default-sort-direction="defaultSortDirection"
      :pagination-rounded="isPaginationRounded"
      :sort-icon="sortIcon"
      :sort-icon-size="sortIconSize"
      default-sort="user.first_name"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      :page-input="hasInput"
      :pagination-order="paginationOrder"
      :page-input-position="inputPosition"
      :debounce-page-input="inputDebounce"
      @click="checkedRows = []"
      :checked-rows.sync="checkedRows"
      checkable
      striped>
      <b-table-column v-for="item in columns" :key="item.id"  sortable :label="item.label"  :header-class="item.type==null ? 'customer-table-header' : 'checkbox-cell'"
                      :field="item.field" v-slot="props" >
        <a v-if="item.linkid!=null" @click="rowClick(props.row[item.linkid])" >
          {{ props.row[item.field]}}
        </a>
        <span v-else-if="item.type=='delete'">
           <b-button type="is-danger"
                     icon-right="delete" @click="deleteClick(item.field, item.url, props.row)"/>
        </span>
        <span v-else>
          {{ props.row[item.field]}}
        </span>
      </b-table-column>
    </b-table>

</template>

<script>
  import api from '@/api.js'

  export default {
    name: 'result',
    props: ['searchData', 'columnsSet'],
    methods: {
      loadGrid: async function(postData){
          this.isLoading = true
          const resultData = await api.post(this.url, postData)
          this.data = resultData.data.list
          this.isLoading = false
          this.postData = postData
      },
      deleteClick: function(idName, url, row){
        const idValue = row[idName]
        this.$buefy.dialog.confirm({
          title: 'Deleting',
          message: '<span style>確認刪除?</span>',
          confirmText: 'Delete',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => this.confirmDelete(idName, url, idValue)
        })
      },
      async confirmDelete(idName, url, idValue){
        const resultData = await api.get(url + '?' + idName + '=' + idValue)
        const isPass = resultData.data.result
        if (isPass) {
          this.$buefy.snackbar.open({
            message: 'Done',
            queue: false
          })
        } else {
          this.$buefy.snackbar.open({
            message: '刪除失敗, 字數據未有清空',
            queue: false
          })
        }
        this.loadGrid(this.postData)
      },
      rowClick(row){
        this.$emit('tabChange', row)
      }
    },
    watch: {
      url: function (val) {
        this.loadGrid('')
      },
      data: function(val) {
        this.isLoading = false
      }
    },
    data() {
      return {
        data: [],
        columns: [],
        url: '',
        isLoading: false,
        isPaginated: true,
        isPaginationSimple: false,
        isPaginationRounded: false,
        paginationPosition: 'bottom',
        defaultSortDirection: 'asc',
        sortIcon: 'arrow-up',
        sortIconSize: 'is-small',
        currentPage: 1,
        perPage: 10,
        hasInput: false,
        postData: {},
        paginationOrder: '',
        inputPosition: '',
        inputDebounce: '',
        checkedRows: []
      }
    }
  }
</script>
<style scoped>
  a:hover{
    text-decoration: underline;
  }
</style>
