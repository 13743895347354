import { render, staticRenderFns } from "./dodoOption.vue?vue&type=template&id=1fd2ecf4&"
import script from "./dodoOption.vue?vue&type=script&lang=js&"
export * from "./dodoOption.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports